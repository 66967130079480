var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('div',{staticClass:"text-h4 mb-10"},[_vm._v("Inventories")]),(_vm.loading)?_c('div',{staticClass:"v-picker--full-width d-flex justify-center"},[_c('v-progress-circular',{attrs:{"size":70,"width":7,"color":"purple","indeterminate":""}})],1):_vm._e(),_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSearch.apply(null, arguments)}}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.inventories,"fixed-header":"","height":"600px","footer-props":{
        'items-per-page-text': 'Items per page',
        'items-per-page-options': [10, 25, 50, -1],
      }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('div',{staticClass:"d-flex",staticStyle:{"width":"30%"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","dense":"","outlined":"","single-line":"","hide-details":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.onSearch.apply(null, arguments)}},model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}}),_c('v-btn',{staticClass:"ml-2 white--text",attrs:{"color":"light-blue","dark":""},on:{"click":_vm.onSearch}},[_vm._v("Search")])],1)])]},proxy:true},{key:"item.defaultSupplier",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.getSupplierNameById(_vm.suppliers, item.defaultSupplierId)))])]}},{key:"item.history",fn:function(ref){
      var item = ref.item;
return [_c('ShowPurchaseHistory',{attrs:{"bodyRequest":item}})]}},{key:"item.edit",fn:function(ref){
      var item = ref.item;
return [_c('UpdateInventoryForm',{attrs:{"bodyRequest":item,"suppliers":_vm.suppliers}})]}},{key:"item.delete",fn:function(ref){
      var item = ref.item;
return [_c('v-icon',{on:{"click":function($event){return _vm.deleteInventoryById(item)}}},[_vm._v("mdi-delete-outline")])]}}],null,true)},[_c('v-divider',{attrs:{"inset":""}})],1)],1),[_c('div',{staticClass:"text-center"},[_c('v-pagination',{attrs:{"length":_vm.totalPages,"total-visible":"7"},on:{"input":_vm.handlePageChange},model:{value:(_vm.curPage),callback:function ($$v) {_vm.curPage=$$v},expression:"curPage"}})],1)],_c('AddInventoryForm',{attrs:{"suppliers":_vm.suppliers}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }