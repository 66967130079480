<template>
  <v-container>
    <div class="text-h4 mb-10">Inventories</div>
    <div class="v-picker--full-width d-flex justify-center" v-if="loading">
      <v-progress-circular
        :size="70"
        :width="7"
        color="purple"
        indeterminate
      ></v-progress-circular>
    </div>
    <form @submit.prevent="onSearch">
      <v-data-table
        :headers="headers"
        :items="inventories"
        class="elevation-1"
        fixed-header
        height="600px"
        :footer-props="{
          'items-per-page-text': 'Items per page',
          'items-per-page-options': [10, 25, 50, -1],
        }"
      >
        <v-divider inset></v-divider>
        <template v-slot:top>
          <v-toolbar flat color="white">
            <div class="d-flex" style="width: 30%">
              <v-text-field
                v-model="searchTerm"
                append-icon="mdi-magnify"
                label="Search"
                dense
                outlined
                single-line
                hide-details
                @keyup.enter="onSearch"
              ></v-text-field>
              <v-btn
                color="light-blue"
                class="ml-2 white--text"
                dark
                @click="onSearch"
                >Search</v-btn
              >
            </div>
          </v-toolbar>
        </template>
        <template v-slot:[`item.defaultSupplier`]="{ item }">
          <span>{{
            getSupplierNameById(suppliers, item.defaultSupplierId)
          }}</span>
        </template>
        <template v-slot:[`item.history`]="{ item }">
          <ShowPurchaseHistory :bodyRequest="item" />
        </template>
        <template v-slot:[`item.edit`]="{ item }">
          <UpdateInventoryForm :bodyRequest="item" :suppliers="suppliers" />
        </template>
        <template v-slot:[`item.delete`]="{ item }">
          <v-icon @click="deleteInventoryById(item)">mdi-delete-outline</v-icon>
        </template>
      </v-data-table>
    </form>
    <template>
      <div class="text-center">
        <v-pagination
          v-model="curPage"
          :length="totalPages"
          total-visible="7"
          @input="handlePageChange"
        ></v-pagination>
      </div>
    </template>
    <AddInventoryForm :suppliers="suppliers" />
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { getSupplierNameById } from "@/helper/tools";
import AddInventoryForm from "@/components/AddInventoryForm.vue";
import UpdateInventoryForm from "@/components/UpdateInventoryForm.vue";
import ShowPurchaseHistory from "@/components/ShowPurchaseHistory.vue";

export default {
  name: "Inventories",
  components: {
    AddInventoryForm,
    UpdateInventoryForm,
    ShowPurchaseHistory,
  },
  mounted() {
    this.fetchInventories();
    this.fetchSuppliers();
  },
  methods: {
    ...mapActions("inventoryModule", [
      "getInventoriesListAction",
      "deleteInventoryByIdAction",
    ]),
    ...mapActions("supplierModule", ["getSuppliersListAction"]),
    fetchInventories() {
      const params = this.getRequestParams(
        this.searchTerm,
        this.curPage,
        this.pageSize
      );
      this.getInventoriesListAction(params);
    },
    fetchSuppliers() {
      this.getSuppliersListAction();
    },
    handlePageChange(value) {
      this.curPage = value;
      //console.log(this.curPage);
      this.fetchInventories();
    },
    deleteInventoryById(inventory) {
      const confirmed = confirm(
        "Are you sure you want to delete the inventory \n\n " + inventory.name
      );
      if (!confirmed) return;
      this.deleteInventoryByIdAction(inventory.id);
    },
    getRequestParams(searchTerm, curPage, pageSize) {
      let params = {};

      searchTerm == undefined ? "" : searchTerm;
      params["searchTerm"] = searchTerm;
      if (curPage) params["curPage"] = curPage;
      if (pageSize) params["pageSize"] = pageSize;

      console.log("searchTerm: " + searchTerm);

      return params;
    },
    onSearch() {
      this.curPage = 1;
      this.fetchInventories();
    },
    getSupplierNameById,
  },
  computed: {
    ...mapGetters("inventoryModule", {
      inventories: "inventories",
      loading: "loading",
      totalPages: "totalPages",
    }),
    ...mapGetters("supplierModule", {
      suppliers: "suppliers",
    }),
  },
  data() {
    return {
      searchTerm: "",
      curPage: 1,
      pageSize: 10,
      headers: [
        { text: "Id", value: "id", sortable: false },
        {
          text: "SKU",
          value: "sku",
          sortable: false,
        },
        {
          text: "Name",
          value: "name",
          sortable: false,
        },
        { text: "Default Supplier", value: "defaultSupplier", sortable: false },
        { text: "Alert Level", value: "alertLevel", sortable: false },
        { text: "Units per Case", value: "unitsPerCase", sortable: false },
        { text: "Bin Location", value: "binLocation", sortable: false },
        { text: "History", value: "history", sortable: false },
        { text: "Edit", value: "edit", sortable: false },
        { text: "Delete", value: "delete", sortable: false },
      ],
    };
  },
};
</script>
