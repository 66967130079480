<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="600px">
      <template v-slot:activator="{ on, attrs }">
        <v-icon v-bind="attrs" v-on="on">mdi-clipboard-edit-outline</v-icon>
      </template>
      <v-card>
        <form @submit.prevent="onSubmit">
          <v-card-title>
            <span class="headline">Edit Inventory</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-text-field
                    required
                    label="SKU"
                    disabled
                    v-model="bodyRequest.sku"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12">
                  <v-text-field
                    required
                    label="Name"
                    v-model="bodyRequest.name"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-select
                    required
                    label="Default Supplier"
                    v-model="bodyRequest.defaultSupplierId"
                    :items="suppliers"
                    item-text="supplierName"
                    item-value="id"
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    required
                    label="Alert Level"
                    v-model="bodyRequest.alertLevel"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-text-field
                    required
                    label="Units per Case"
                    v-model="bodyRequest.unitsPerCase"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    label="Bin Location"
                    v-model="bodyRequest.binLocation"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text color="blue darken-1" @click="dialog = false"
              >Cancel</v-btn
            >
            <v-btn
              text
              color="blue darken-1"
              @click="dialog = false"
              type="submit"
              >Save</v-btn
            >
          </v-card-actions>
        </form>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "UpdateSupplierForm",

  props: {
    bodyRequest: {
      type: Object,
      required: true,
      default: () => ({
        sku: "",
        name: "",
        defaultSupplierId: 0,
        alertLevel: 0,
        unitsPerCase: 1,
        binLocation: "",
      }),
    },
    suppliers: [],
  },

  methods: {
    ...mapActions("inventoryModule", ["updateInventoryAction"]),
    onSubmit() {
      this.updateInventoryAction(this.bodyRequest);
    },
  },
  data: () => ({
    dialog: false,
  }),
};
</script>
