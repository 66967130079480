<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="600px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          style="margin-top: 1rem"
          rounded
          color="light-blue"
          dark
          v-bind="attrs"
          v-on="on"
        >
          <v-icon left>mdi-plus</v-icon>
          Add a New Inventory
        </v-btn>
      </template>
      <v-card>
        <form
          @submit.prevent="
            createInventoryAction(bodyRequest);
            bodyRequest = {};
          "
        >
          <v-card-title>
            <span class="headline">Add a New Inventory</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-text-field
                    required
                    label="SKU"
                    v-model="bodyRequest.sku"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12">
                  <v-text-field
                    required
                    label="Name"
                    v-model="bodyRequest.name"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-select
                    required
                    label="Default Supplier"
                    v-model="bodyRequest.defaultSupplierId"
                    :items="suppliers"
                    item-text="supplierName"
                    item-value="id"
                  ></v-select>
                </v-col>

                <v-col cols="12" sm="6">
                  <v-text-field
                    required
                    label="Alert Level"
                    v-model="bodyRequest.alertLevel"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-text-field
                    required
                    label="Units per Case"
                    v-model="bodyRequest.unitsPerCase"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    label="Bin Location"
                    v-model="bodyRequest.binLocation"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialog = false"
              >Cancel</v-btn
            >
            <v-btn
              color="blue darken-1"
              text
              @click="dialog = false"
              type="submit"
              >Save</v-btn
            >
          </v-card-actions>
        </form>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "AddInventoryForm",
  data: () => ({
    bodyRequest: {
      sku: "",
      name: "",
      defaultSupplierId: 0,
      alertLevel: 1,
      unitsPerCase: 1,
      binLocation: "",
    },
    dialog: false,
  }),
  props: ["suppliers"],
  methods: {
    ...mapActions("inventoryModule", ["createInventoryAction"]),
  },
};
</script>
